import * as amplitude from '@amplitude/analytics-browser'

export enum AmpliServiceTrackTypes {
  SUBMIT_FORM = 'Submit form',
  ELEMENT_CLICK = 'Element click',
  BECOME_MEMBER = 'Become member'
}

export type AmpliTrackProps = Record<string, unknown>

export class AmpliService {
  instance: typeof amplitude
  context = 'Ремонты'

  userProps = {
    'Context': this.context,
    'Screen name': 'экран информация'
  }

  constructor () {
    this.instance = amplitude
    this.instance.init(process.env.VUE_APP_AMPLITUDE_APIKEY, {
      defaultTracking: false
    })
  }

  trackSubmitForm (props: AmpliTrackProps) {
    this.instance.track(AmpliServiceTrackTypes.SUBMIT_FORM, {
      ...props,
      ...this.userProps
    })
  }

  trackElementClick (props: AmpliTrackProps) {
    this.instance.track(AmpliServiceTrackTypes.ELEMENT_CLICK, {
      ...props,
      ...this.userProps
    })
  }

  trackBecomeMember (props: AmpliTrackProps) {
    this.instance.track(AmpliServiceTrackTypes.BECOME_MEMBER, {
      ...props,
      ...this.userProps
    })
  }
}
