<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator'
import Loader from '@/components/ui/Loader'

@Component({
  components: {
    Loader
  }
})
export default class LoaderScreen extends Vue {
  @VModel({ type: Boolean }) state!: false
}
</script>

<template>
  <div v-show="state" class="c-loader-screen">
    <Loader/>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
