<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Banner extends Vue {
  @Prop(String) readonly title: string
  @Prop(String) readonly text: string
  @Prop(String) readonly image: string

  get style () {
    const _style: Record<string, string> = {}

    if (this.image) {
      _style.backgroundImage = `url(${this.image})`
    }

    return _style
  }
}
</script>

<template>
  <div class="c-banner" :style="style">
    <div class="c-banner__body">
      <p class="c-banner__title">
        {{ title }}
      </p>

      <span class="c-banner__text">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
