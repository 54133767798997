<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Promo extends Vue {
  @Prop({
    type: String,
    default: ''
  }) readonly title!: string

  @Prop({
    type: String,
    default: ''
  }) readonly description!: string

  @Prop({
    type: String,
    default: ''
  }) readonly caption!: string

  @Prop({
    type: String,
    default: ''
  }) readonly thumbnail!: string

  @Prop(Boolean)
  readonly collapse: boolean

  get classes () {
    return {
      'c-promo_collapsed': this.collapse
    }
  }

  get thumbnailStyle () {
    return {
      backgroundImage: `url('${this.thumbnail}')`
    }
  }
}
</script>

<template>
  <div
    class="c-promo"
    :class="classes"
    :style="thumbnailStyle"
  >
    <div class="c-promo__main">
      <div class="c-promo__title">
        {{ title }}
      </div>

      <p v-if="description" class="c-promo__description">
        {{ description }}
      </p>

      <small v-if="caption" class="c-promo__caption">
        {{ caption }}
      </small>
    </div>

    <div class="c-promo__extras">
      <slot name="extras"/>
    </div>

    <div v-if="$slots.action" class="c-promo__action">
      <slot name="action"/>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
