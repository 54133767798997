// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjMDBCMENDIiBzdHJva2Utd2lkdGg9IjEuNjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxwYXRoIGQ9Im0xMS41MTUgMy43ODctLjE1OS0uMjIyYTEuNjY3IDEuNjY3IDAgMCAwLTIuNzEzIDBsLS4xNTguMjIyYy0uMzUxLjQ5Mi0uOTQuNzU1LTEuNTQuNjg4bC0uNzA4LS4wNzlhMS42NjcgMS42NjcgMCAwIDAtMS44NCAxLjg0MWwuMDc4LjcwOGMuMDY3LjYtLjE5NiAxLjE4OS0uNjg4IDEuNTRsLS4yMjIuMTU4YTEuNjY3IDEuNjY3IDAgMCAwIDAgMi43MTNsLjIyMi4xNTljLjQ5Mi4zNS43NTUuOTQuNjg4IDEuNTRsLS4wNzkuNzA3YTEuNjY3IDEuNjY3IDAgMCAwIDEuODQxIDEuODQxbC43MDgtLjA3OWMuNi0uMDY2IDEuMTg5LjE5NyAxLjU0LjY4OGwuMTU4LjIyMmMuNjY1LjkzIDIuMDQ4LjkzIDIuNzEzIDBsLjE1OS0uMjIyYy4zNS0uNDkxLjk0LS43NTQgMS41NC0uNjg4bC43MDcuMDc5YTEuNjY3IDEuNjY3IDAgMCAwIDEuODQxLTEuODRsLS4wNzktLjcwOGMtLjA2Ni0uNi4xOTctMS4xOS42ODgtMS41NGwuMjIyLS4xNTljLjkzLS42NjUuOTMtMi4wNDggMC0yLjcxM2wtLjIyMi0uMTU4YTEuNjY3IDEuNjY3IDAgMCAxLS42ODgtMS41NGwuMDc5LS43MDhhMS42NjcgMS42NjcgMCAwIDAtMS44NC0xLjg0bC0uNzA4LjA3OGMtLjYuMDY3LTEuMTktLjE5Ni0xLjU0LS42ODhaIi8+PHBhdGggZD0ibTcuNSAxMCAxLjUxNiAxLjUxNmEuMjE0LjIxNCAwIDAgMCAuMzAyIDBMMTIuNSA4LjMzNCIvPjwvZz48L3N2Zz4=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-info-list{display:flex;flex-direction:column;gap:20px}.c-info-list__title{font-size:20px;font-weight:700;line-height:24px;letter-spacing:.15px}.c-info-list__list{display:flex;flex-direction:column;gap:8px;list-style-type:none}.c-info-list__list li{padding-left:28px;font-size:14px;font-weight:400;line-height:20px;letter-spacing:.21px;position:relative}.c-info-list__list li:before{content:\"\";width:20px;height:20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;position:absolute;top:0;left:0}.c-info-list li a{color:#00b0cc;text-decoration:none}.c-info-list_numerable .c-info-list__list{counter-reset:item}.c-info-list_numerable .c-info-list__list li{padding-left:30px}.c-info-list_numerable .c-info-list__list li:before{content:counter(item);counter-increment:item;display:flex;align-items:center;justify-content:center;background-color:#efeff4;background-image:none;border-radius:3px;font-size:12px;font-weight:400;line-height:18px;letter-spacing:.24px;top:2px;left:2px;transform:translateY(-2px)}.c-info-list_extended .c-info-list__list{gap:24px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
