<script lang="ts">
import { Vue, Component, Ref } from 'vue-property-decorator'
import LoaderScreen from '@/components/entity/LoaderScreen'

@Component({
  components: {
    LoaderScreen
  }
})
export default class MapProvider extends Vue {
  ymapUrl = process.env.VUE_APP_MAP_SRC
  loading = true

  @Ref('iframe') readonly iframe!: HTMLIFrameElement

  onLoad () {
    this.loading = false
  }
}
</script>

<template>
  <div class="c-map-provider">
    <iframe :src="ymapUrl" frameborder="0" ref="iframe" sandbox="allow-same-origin allow-scripts" @load="onLoad"/>

    <LoaderScreen v-model="loading"/>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
