<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Default extends Vue {
  @Prop(Boolean)
  readonly collapse: boolean

  get classes () {
    return {
      'c-default-layout_collapsed': this.collapse
    }
  }
}
</script>

<template>
  <div class="c-default-layout" :class="classes">
    <main class="c-default-layout__main">
      <div class="c-default-layout__body">
        <slot/>
      </div>
    </main>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
