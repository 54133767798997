<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Section extends Vue {
  @Prop({ type: String, default: '' }) readonly icon!: string
  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: String, default: '' }) readonly description!: string
}
</script>

<template>
  <div class="c-header-section">
    <div v-if="icon" class="c-header-section__icon">
      <img :src="icon">
    </div>

    <div class="c-header-section__content">
      <div class="c-header-section__title">
        {{ title }}
      </div>

      <div class="c-header-section__description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
