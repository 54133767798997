<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoBlock from '@/components/entity/InfoBlock'

type TInfoListItems = {
  image?: string
  title?: string
  text: string
}

@Component({
  components: {
    InfoBlock
  }
})
export default class InfoList extends Vue {
  @Prop({
    type: Boolean,
    default: false
  }) readonly numerable!: false

  @Prop({
    type: String,
    default: ''
  }) readonly title!: string

  @Prop({
    type: Array,
    default: () => []
  }) readonly items!: string[] | TInfoListItems[]

  get isExtended () {
    const example = this.items.length ? this.items[0] : ''

    return typeof example === 'object'
  }

  get classes () {
    return {
      'c-info-list_numerable': this.numerable,
      'c-info-list_extended': this.isExtended
    }
  }
}
</script>

<template>
  <div
    class="c-info-list"
    :class="classes"
  >
    <div class="c-info-list__title">
      {{ title }}
    </div>

    <ul class="c-info-list__list">
      <template v-if="isExtended">
        <!-- @vue-skip -->
        <InfoBlock
          v-for="(item, index) in items"
          :key="index"
          :image="item.image"
          :title="item.title"
          :description="item.text"
        />
      </template>

      <template v-else>
        <li
          v-for="(item, index) in items"
          :key="index"
          v-html="item"
        />
      </template>

      <slot/>
    </ul>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
