<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Avatar extends Vue {
  @Prop(String) readonly src!: string

  @Prop({
    type: String,
    default: ''
  }) readonly color!: string

  @Prop({
    type: [String, Number],
    default: 80
  }) readonly size!: string

  get styles () {
    return {
      width: this.size + 'px',
      height: this.size + 'px',
      'background-color': this.background
    }
  }

  get background () {
    return this.color ?? 'transparent'
  }
}
</script>

<template>
  <div :style="styles" class="c-avatar">
    <img :src="src">
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
