<script lang="ts">
import { Component, Vue, Watch, Provide } from 'vue-property-decorator'
import { AmpliService } from './services/amplitude.service'

import Default from '@/components/layout/Default'
import Header from '@/components/layout/Header'
import Promo from '@/components/entity/Promo'
import Tabs from '@/components/entity/Tabs'
import Info from '@/components/content/Info'
import MapProvider from '@/components/content/MapProvider'

@Component({
  components: {
    Default,
    Header,
    Promo,
    Tabs,
    Info,
    MapProvider
  }
})
export default class App extends Vue {
  @Provide()
  ampli = new AmpliService()

  appReady = false

  collapseLayout = false

  activeTab = 'info'
  tabsOptions = [
    { name: 'Информация', value: 'info' },
    { name: 'Точки на карте', value: 'marks' }
  ]

  promoThumbnail = require('@/assets/images/thumbnail-car.png')

  @Watch('activeTab')
  onActiveTabChanged (val: string) {
    if (val === 'marks') {
      this.collapseLayout = true
    } else {
      this.collapseLayout = false
    }

    const elName = this.tabsOptions.find(_ => _.value === val)

    this.ampli.trackElementClick({
      'Element name': elName?.name
    })
  }

  isPage (page: string) {
    return this.activeTab === page
  }
}
</script>

<template>
  <Default class="app-repair-page" :collapse="collapseLayout">
    <Promo
      title="Ремонт и запчасти"
      description="Ремонт и запчасти у наших партнеров — широкая сеть обслуживания по всей стране"
      :thumbnail="promoThumbnail.default"
      :collapse="collapseLayout"
    >
      <template #extras>
        <Tabs v-model="activeTab" :options="tabsOptions"/>
      </template>
    </Promo>

    <Info v-if="isPage('info')"/>

    <MapProvider v-if="isPage('marks')"/>
  </Default>
</template>

<style lang="scss" src="./assets/scss/index.scss"></style>
