<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class SectionGroup extends Vue {}
</script>

<template>
  <div class="c-header-section-group">
    <slot/>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
