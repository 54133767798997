import Cross from './Cross'
import Point from './Point'
import Clock from './Clock'
import Logo from './Logo'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'

export default {
  Cross,
  Point,
  Clock,
  Logo,
  ArrowLeft,
  ArrowRight
}
