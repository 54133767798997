<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator'

import Avatar from '@/components/ui/Avatar'
import Button from '@/components/ui/Button'
import Icons from '@/components/ui/Icons'

@Component({
  components: {
    Avatar,
    Button,
    IconCross: Icons.Cross
  }
})
export default class PartnerDetail extends Vue {
  @VModel({ type: Boolean }) state!: boolean

  @Prop({ type: String, default: '' })
  readonly title!: string

  @Prop({ type: String, default: '' })
  readonly color!: string

  @Prop({ type: String, default: '' })
  readonly image!: string

  onCloseClick () {
    this.state = false
  }
}
</script>

<template>
  <Transition name="swipe">
    <div v-show="state" class="c-partner-detail">
      <div class="c-partner-detail__body">
        <header class="c-partner-detail__header">
          <div class="c-partner-detail__title">
            {{ title }}
          </div>

          <Button
            color="neutral"
            size="s"
            icon
            @click.native="onCloseClick"
          >
            <IconCross/>
          </Button>
        </header>

        <div class="c-partner-detail__main">
          <div class="c-partner-detail__main-top">
            <div class="c-partner-detail__banner">
              <slot name="banner"/>
            </div>

            <div class="c-partner-detail__info">
              <slot name="info"/>

              <Avatar v-if="image" :color="color" :src="image"/>
            </div>
          </div>

          <div class="c-partner-detail__content">
            <slot name="default"/>
          </div>
        </div>

        <footer class="c-partner-detail__footer">
          <slot name="footer"/>
        </footer>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" src="./style.scss"></style>
