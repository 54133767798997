<script functional>
export default {}
</script>

<template>
  <svg width="86" height="24" viewBox="0 0 86 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 10.2535L16.6352 5.32695C15.4077 4.503 13.794 4.503 12.5665 5.32695L11.4678 6.05649L0 13.7467L3.1588 15.8581L6.2661 17.9438L7.35623 18.6733C8.59228 19.4973 10.206 19.4973 11.4335 18.6733L17.6996 14.4763L24 10.2535Z" fill="url(#paint0_linear_4131_1026)"/>

    <path d="M35.576 20.2161C34.968 20.2161 34.504 20.0481 34.184 19.7121C33.864 19.3761 33.704 18.9041 33.704 18.2961V5.02408C33.704 4.40008 33.872 3.92008 34.208 3.58408C34.544 3.24808 35.024 3.08008 35.648 3.08008H46.904C47.528 3.08008 48.008 3.24808 48.344 3.58408C48.68 3.92008 48.848 4.40008 48.848 5.02408V18.2961C48.848 18.9041 48.688 19.3761 48.368 19.7121C48.048 20.0481 47.592 20.2161 47 20.2161C46.392 20.2161 45.928 20.0481 45.608 19.7121C45.288 19.3761 45.128 18.9041 45.128 18.2961V6.15208H37.424V18.2961C37.424 18.9041 37.264 19.3761 36.944 19.7121C36.64 20.0481 36.184 20.2161 35.576 20.2161Z" fill="#151719"/>

    <path d="M54.1151 20.2161C53.5071 20.2161 53.0431 20.0481 52.7231 19.7121C52.4031 19.3761 52.2431 18.9041 52.2431 18.2961V5.02408C52.2431 4.40008 52.4111 3.92008 52.7471 3.58408C53.0831 3.24808 53.5631 3.08008 54.1871 3.08008H65.4431C66.0671 3.08008 66.5471 3.24808 66.8831 3.58408C67.2191 3.92008 67.3871 4.40008 67.3871 5.02408V18.2961C67.3871 18.9041 67.2271 19.3761 66.9071 19.7121C66.5871 20.0481 66.1311 20.2161 65.5391 20.2161C64.9311 20.2161 64.4671 20.0481 64.1471 19.7121C63.8271 19.3761 63.6671 18.9041 63.6671 18.2961V6.15208H55.9631V18.2961C55.9631 18.9041 55.8031 19.3761 55.4831 19.7121C55.1791 20.0481 54.7231 20.2161 54.1151 20.2161Z" fill="#151719"/>

    <path d="M72.6541 20.2161C72.0461 20.2161 71.5821 20.0481 71.2621 19.7121C70.9421 19.3761 70.7821 18.9041 70.7821 18.2961V4.97608C70.7821 4.35208 70.9421 3.88008 71.2621 3.56008C71.5981 3.24008 72.0701 3.08008 72.6781 3.08008H78.5101C80.3981 3.08008 81.8541 3.56008 82.8781 4.52008C83.9021 5.46408 84.4141 6.78408 84.4141 8.48008C84.4141 10.1761 83.9021 11.5041 82.8781 12.4641C81.8541 13.4241 80.3981 13.9041 78.5101 13.9041H74.5021V18.2961C74.5021 18.9041 74.3421 19.3761 74.0221 19.7121C73.7181 20.0481 73.2621 20.2161 72.6541 20.2161ZM74.5021 11.0241H77.8861C78.8461 11.0241 79.5741 10.8081 80.0701 10.3761C80.5821 9.94408 80.8381 9.31208 80.8381 8.48008C80.8381 7.64808 80.5821 7.02408 80.0701 6.60808C79.5741 6.19208 78.8461 5.98408 77.8861 5.98408H74.5021V11.0241Z" fill="#151719"/>

    <defs>
      <linearGradient id="paint0_linear_4131_1026" x1="21.6481" y1="19.2913" x2="1.57082" y2="19.2913" gradientUnits="userSpaceOnUse">
        <stop stop-color="#68F9BE"/>

        <stop offset="0.5" stop-color="#00C1DC"/>

        <stop offset="1" stop-color="#0165C0"/>
      </linearGradient>
    </defs>
  </svg>
</template>
