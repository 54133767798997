<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

type TButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl'
type TButtonColor = 'accent' | 'info' | 'neutral'
type TButtonTag = 'button' | 'router-link' | 'a'

@Component
export default class Button extends Vue {
  @Prop({ type: String, default: 'button' }) readonly tag!: TButtonTag
  @Prop({ type: String, default: 'm' }) readonly size!: TButtonSize
  @Prop({ type: String, default: 'accent' }) readonly color!: TButtonColor
  @Prop(Boolean) readonly disabled!: boolean
  @Prop(Boolean) readonly icon!: boolean
  @Prop(Boolean) readonly outline!: boolean
  @Prop(Boolean) readonly tone!: boolean
  @Prop(Boolean) readonly volumetric!: boolean
  @Prop(Boolean) readonly block!: boolean

  get classes () {
    return {
      [`c-button_size-${this.size}`]: true,
      [`c-button_color-${this.color}`]: true,
      'c-button_outline': this.outline,
      'c-button_icon': this.icon,
      'c-button_tone': this.tone,
      'c-button_volumetric': this.volumetric,
      'c-button_block': this.block
    }
  }
}
</script>

<template>
  <component
    class="c-button"
    :class="classes"
    :is="tag"
    :disabled="disabled"
  >
    <div class="c-button__icon c-button__icon_prepend">
      <slot name="prependicon"/>
    </div>

    <div class="c-button__label">
      <slot/>
    </div>

    <div class="c-button__icon c-button__icon_append">
      <slot name="appendicon"/>
    </div>
  </component>
</template>

<style lang="scss" src="./button.scss"></style>
