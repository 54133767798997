<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoSlot extends Vue {
  @Prop({
    type: String,
    default: ''
  }) readonly title!: string
}
</script>

<template>
  <div class="c-info-slot">
    <p class="c-info-slot__title">
      {{ title }}
    </p>

    <div class="c-info-slot__content">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
