<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

import SectionGroup from './SectionGroup'
import Section from './Section'
import Icons from '@/components/ui/Icons'

@Component({
  components: {
    SectionGroup,
    Section,
    Logo: Icons.Logo
  }
})
export default class Header extends Vue {}
</script>

<template>
  <div class="c-header">
    <div class="c-header__container">
      <div class="c-header__logo">
        <Logo/>
      </div>

      <div class="c-header__info">
        <SectionGroup>
          <Section
            icon="/icons/mint-contract.svg"
            title="Договор"
            description="4-334567"
          />

          <Section
            icon="/icons/mint-apartment.svg"
            title="Компания"
            description="СтройСпецТехникаАвто"
          />
        </SectionGroup>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
