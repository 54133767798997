<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator'

type TTabsOption = {
  name: string
  value: string
}

@Component
export default class Tabs extends Vue {
  @VModel({ type: String }) active!: string

  @Prop({
    type: Array,
    default: () => []
  }) readonly options!: TTabsOption[]

  onClick (value: string) {
    this.active = value
  }
}
</script>

<template>
  <div class="c-tabs">
    <div class="c-tabs__list">
      <div
        v-for="item in options"
        :key="item.value"
        class="c-tabs__item"
        :class="{
          'c-tabs__item_active': item.value === active
        }"
        @click="onClick(item.value)"
      >
        <span>
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
