<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator'

import Button from '@/components/ui/Button'

type TTabsOption = {
  name: string
  value: string
}

@Component({
  components: {
    Button
  }
})
export default class TabContent extends Vue {
  @VModel({ type: String }) active!: string

  @Prop({
    type: Array,
    default: () => []
  }) readonly options!: TTabsOption[]

  onClick (value: string) {
    this.active = value
  }
}
</script>

<template>
  <div class="c-tab-content">
    <Button
      tone
      :color="active === 'all' ? 'accent' : 'neutral'"
      @click.native="onClick('all')"
    >
      Все
    </Button>

    <Button
      v-for="item in options"
      :key="item.value"
      tone
      :color="active === item.value ? 'accent' : 'neutral'"
      @click.native="onClick(item.value)"
    >
      {{ item.name }}
    </Button>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
