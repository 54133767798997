<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Avatar from '@/components/ui/Avatar'

@Component({
  components: {
    Avatar
  }
})
export default class InfoBlock extends Vue {
  @Prop({
    type: String,
    default: ''
  }) readonly image!: string

  @Prop({
    type: String,
    default: ''
  }) readonly color!: string

  @Prop({
    type: String,
    default: ''
  }) readonly title!: string

  @Prop({
    type: String,
    default: ''
  }) readonly description!: string

  onActionClick () {
    this.$emit('action-click')
  }
}
</script>

<template>
  <div class="c-info-block">
    <div v-if="image" class="c-info-block__image">
      <Avatar :src="image" :color="color"/>
    </div>

    <div class="c-info-block__content">
      <div class="c-info-block__title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>

      <div class="c-info-block__description">
        <slot name="description">
          {{ description }}
        </slot>
      </div>

      <div class="c-info-block__action">
        <slot name="action">
          <a href="#" @click.prevent="onActionClick">Подробнее ↗</a>
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
