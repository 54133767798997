<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Loader extends Vue {}
</script>

<template>
  <div class="c-loader"/>
</template>

<style lang="scss" src="./style.scss"></style>
