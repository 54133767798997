<script lang="ts">
import { Vue, Component, VModel, Inject } from 'vue-property-decorator'
import { AmpliService } from '@/services/amplitude.service'

import PartnerDetail from '@/components/entity/PartnerDetail'
import InfoList from '@/components/entity/InfoList'
import Divider from '@/components/ui/Divider'
import Button from '@/components/ui/Button'
import Icons from '@/components/ui/Icons'

@Component({
  components: {
    PartnerDetail,
    InfoList,
    Divider,
    Button,
    IconArrowLeft: Icons.ArrowLeft,
    IconArrowRight: Icons.ArrowRight
  }
})
export default class FitServiceDetail extends Vue {
  @Inject('ampli') readonly ampli!: AmpliService

  @VModel({ type: Boolean }) state!: boolean

  avatar = require('@/assets/images/fitservice-avatar.png')
  banner = require('@/assets/images/fit-service-banner.png')

  features = [
    'Возврат 20% НДС',
    'Более 300 точек обслуживания по всей России',
    'Сохранение дилерской гарантии',
    'Широкий выбор запчастей: оригинальных и аналогов, с доставкой в выбранный СТО',
    'Оплата с единого счета ППР и единый формат закрывающих документов',
    'Консультация технического эксперта',
    'Круглосуточная онлайн-запись'
  ]

  get bannerStyle () {
    return {
      backgroundImage: `url('${this.banner.default}')`
    }
  }

  onHotjarLinkClick (trackElementName: string) {
    this.ampli.trackElementClick({
      'Element name': trackElementName
    })
  }

  onActionClick () {
    this.ampli.trackElementClick({
      'Element name': 'кнопка войти в фит'
    })
  }

  onPrevClick () {
    this.$emit('prev')
  }

  onNextClick () {
    this.$emit('next')
  }
}
</script>

<template>
  <PartnerDetail
    v-model="state"
    title="Fit Service"
    color="#F5F6F7"
    :image="avatar.default"
  >
    <template #banner>
      <div class="gc-detail__banner" :style="bannerStyle">
        <div class="gc-detail__banner-title">
          Бесплатная диагностика тормозной&nbsp;системы
        </div>

        <div class="gc-detail__banner-subtitle">
          Акция&nbsp;до 30.04.2024
        </div>
      </div>
    </template>

    <template #info>
      <div class="gc-detail__info">
        <p>
          FIT SERVICE&nbsp;— это не&nbsp;просто самая быстрорастущая сеть автосервисов по&nbsp;всей России от&nbsp;Москвы до&nbsp;Владивостока. Это тысячи клиентов, которые нам доверяют. Это дружная команда людей, вдохновленных общей идеей и&nbsp;общим делом. Это строгие стандарты качества во&nbsp;всем и&nbsp;постоянная поддержка наших клиентов, где&nbsp;бы&nbsp;они ни&nbsp;находились.
        </p>
      </div>
    </template>

    <template #default>
      <Divider/>

      <InfoList
        title="Преимущества Fit Service"
        :items="features"
      />

      <Divider/>

      <InfoList
        title="Как пользоваться кабинетом партнера"
        manual
        numerable
      >
        <li>
          Нажмите на кнопку «Перейти в Fit Service»
        </li>

        <li>
          Войдите в личный кабинет партнера (Если у вас нет доступа, <a href="https://surveys.hotjar.com/318d3d2c-9156-4b9a-a35a-aad678f39b96" target="_blank" @click="onHotjarLinkClick('ссылка на заявку')">оставьте заявку</a>, наш менеджер свяжется c вами)
        </li>

        <li>
          Добавьте автомобили
        </li>

        <li>
          Выберите услугу
        </li>

        <li>
          Запишитесь на выбранную СТО
        </li>
      </InfoList>
    </template>

    <template #footer>
      <div class="gc-detail__actions">
        <Button
          class="gc-detail__ui-action-button"
          color="accent"
          tag="a"
          href="https://fleet.fitauto.ru/auth/strategy/login?strategy=ppr"
          target="_blank"
          @click.native="onActionClick"
        >
          Перейти в Fit Service
        </Button>

        <div class="gc-detail__ui-right-actions">
          <Button
            color="neutral"
            size="s"
            icon
            @click.native="onPrevClick"
          >
            <IconArrowLeft/>
          </Button>

          <Button
            color="neutral"
            size="s"
            icon
            @click.native="onNextClick"
          >
            <IconArrowRight/>
          </Button>
        </div>
      </div>
    </template>
  </PartnerDetail>
</template>

<style lang="scss" src="@/assets/scss/components/detail.scss"></style>
