<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoSection extends Vue {
  @Prop(String) readonly title: string
  @Prop(String) readonly text: string
  @Prop(String) readonly image: string
}
</script>

<template>
  <div class="c-info-section">
    <div class="c-info-section__title">
      {{ title }}
    </div>

    <div class="c-info-section__body">
      <p class="c-info-section__text">
        {{ text }}
      </p>

      <img
        class="c-info-section__image"
        :src="image"
      >
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
